<template>
	<el-scrollbar class="uu-box">
		<div class="trade-platform">
			<div class="message-notice">
				<i class="el-icon-warning"></i>
				<div class="message-notice-main">
					<p>{{$t('seller.order.tips1')}}
						<!-- <a href="#">{{$t('seller.actions.viewDetails')}}</a> -->
					</p>
				</div>
			</div>
			<div class="trade-platform-head" v-if="screenClose">
				<div class="uu-screen">
					<el-form v-model="screenForm" :inline="true">
						<el-form-item>
							<el-input v-model="screenForm.order_sn" clearable>
								<template slot="prefix">{{$t('seller.screen.orderNumber')}}</template>
							</el-input>
						</el-form-item>
						<template v-if="pageName !== 'history'">
							<el-form-item>
								<el-select v-model="screenForm.status" clearable>
									<template slot="prefix">{{$t('seller.screen.orderStatus')}}</template>
									<el-option v-for="(item,index) in orderStatusOption" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-input v-model="screenForm.goods_ids" clearable>
									<template slot="prefix">{{$t('seller.screen.goodsId')}}</template>
								</el-input>
							</el-form-item>
						</template>
						<el-form-item>
							<el-input v-model="screenForm.goods_name" clearable>
								<template slot="prefix">{{$t('seller.screen.babyName')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="screenForm.buyer_name" clearable>
								<template slot="prefix">{{$t('seller.screen.buyerName')}}</template>
							</el-input>
						</el-form-item>
						<template v-if="pageName !== 'history'">
							<!-- <el-form-item>
								<el-select v-model="screenForm.evaluation_status" clearable>
									<template slot="prefix">{{$t('seller.screen.evaluation')}}</template>
									<el-option v-for="(item,index) in evaluationOption" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-select v-model="screenForm.shipping_type" clearable>
									<template slot="prefix">{{$t('seller.screen.logistics')}}</template>
									<el-option v-for="(item,index) in shippingOption" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-select v-model="screenForm.service_status" clearable>
									<template slot="prefix">{{$t('seller.screen.afterSale')}}</template>
									<el-option v-for="(item,index) in afterSaleOption" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item> -->
						</template>
						<el-form-item>
							<div class="uu-date-picker">
								<div class="picker-prefix-label">{{$t('seller.screen.createTime')}}</div>
								<el-date-picker v-model="screenDatetime" type="datetimerange" range-separator="-"
									:start-placeholder="$t('seller.placeholder.originDate')" :end-placeholder="$t('seller.placeholder.endDate')" prefix-icon=" "
									:picker-options="startPickerOptions">
								</el-date-picker>
							</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="uu-action">
					<ul>
						<li>
							<el-button @click="handleSearchList" type="primary" round>{{$t('seller.actions.searchOrder')}}</el-button>
						</li>
						<!-- <li>
							<el-button round>{{$t('seller.actions.batchExport')}}</el-button>
						</li> -->
						<li><span class="clear-btn" @click="handleSearchClear"><i
									class="el-icon-brush"></i>{{$t('seller.actions.reset')}}</span></li>
					</ul>
				</div>
			</div>
			<div class="uu-toggle" v-if="screenClose" @click="screenClose = false"><i
					class="el-icon-caret-top"></i>{{$t('seller.actions.collapse')}}</div>
			<div class="uu-toggle" @click="screenClose = true" v-else><i class="el-icon-caret-bottom"></i>{{$t('seller.actions.expandSearch')}}
			</div>
			<el-tabs v-model="pageName" @tab-click="handleClickPage">
				<el-tab-pane v-for="(item,index) in pageNav" :key="index" :label="item.label" :name="item.value"></el-tab-pane>
			</el-tabs>
			<div class="uu-action mb20" v-if="['recent','accepted'].includes(pageName)">
				<ul>
					<li>
						<el-button @click="handleBatchConsign" round>{{$t('seller.actions.batchShipping')}}</el-button>
					</li>
					<!-- <li>
						<el-button round>{{$t('seller.actions.batchMarking')}}</el-button>
					</li>
					<li>
						<el-button round>{{$t('seller.actions.batchFreightFree')}}</el-button>
					</li> -->
					<li>
						<el-checkbox v-model="screenForm.close_status" @change="handleSearchList" true-label="0" false-label="1"
							v-if="pageName=='recent'">{{$t('seller.checkbox.closeOrder')}}</el-checkbox>
					</li>
				</ul>
			</div>
			<div class="trade-platform-list">
				<table width="100%" cellpadding="2">
					<thead>
						<tr>
							<th width="45px">
								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
									@change="handleCheckAllChange">{{ }}</el-checkbox>
							</th>
							<th style="min-width: 200px;">{{$t('seller.tableHead.baby')}}</th>
							<th>{{$t('seller.tableHead.unitPrice')}}</th>
							<th>{{$t('seller.tableHead.number')}}</th>
							<th>{{$t('seller.tableHead.afterSales')}}</th>
							<th>{{$t('seller.tableHead.payStatus')}}</th>
							<th>{{$t('seller.tableHead.realPayment')}}</th>
							<!-- <th>{{$t('seller.tableHead.evaluate')}}</th> -->
						</tr>
					</thead>
					<template v-if="pageList.length>0">
						<tbody v-for="item,index in pageList" :key="index">
							<tr class="group-header">
								<td align="center">
									<el-checkbox v-model="checkIds" :label="item.order_id"
										@change="handleCheckedOrderChange">{{ }}</el-checkbox>
								</td>
								<td colspan="7">
									<div class="item-head">
										<ul>
											<li><span>{{$t('seller.order.number')}}{{item.order_sn}}</span></li>
											<li><span>{{$t('seller.order.createTime')}}{{item.add_time}}</span></li>
											<li><a href="#">{{item.buyer_name}}</a></li>
										</ul>
									</div>
								</td>
							</tr>
							<tr v-for="(good,index2) in item.order_goods" :key="index2">
								<td colspan="2">
									<div class="item-goods">
										<a :href="$itemBasePath+'/m/item/good/'+good.goods_id" target="_blank" class="pic"><img :src="good.goods_image"
												@error="imageLoadError" alt=""></a>
										<div class="item-main">
											<div class="name"><a :href="$itemBasePath+'/m/item/good/'+good.goods_id" target="_blank">{{good.goods_name}}</a></div>
											<div class="number" v-if="good.goods_code">{{$t('seller.order.goodsCode')}}{{good.goods_code}}</div>
										</div>
									</div>
								</td>
								<td align="center">￥{{good.price}}</td>
								<td align="center">{{good.quantity}}</td>
								<td></td>
								<td :rowspan="item.order_goods.length" align="center" v-if="index2==0">
									<ul class="item-status">
										<li>{{item.status | statusFilter($t('seller.order.statusJson'))}}</li>
										<li><router-link :to="{ name: 'orderDetail', params: { id: item.order_id }}">{{ $t('seller.actions.details') }}</router-link></li>
										<li v-if="item.status==11"><a href="javascript:void(0);" @click="handleShow(item.order_sn)">{{$t('seller.actions.closePay')}}</a></li>
										<li v-if="item.status==20">
											<router-link :to="{ name: 'consignOrder', params: { type:'list',id: item.order_id }}">
												<el-button type="primary" size="small" round>{{$t('seller.actions.shipping')}}</el-button>
											</router-link>
										</li>
										<!-- <li v-if="item.status==30"><a href="javascript:void(0);">{{$t('seller.actions.delayReceiving')}}</a></li> -->
									</ul>
								</td>
								<td :rowspan="item.order_goods.length" align="center" v-if="index2==0">
									<ul class="item-price">
										<li>￥{{item.order_amount}}</li>
										<li><span>{{$t('seller.order.containFreight')}}</span></li>
									</ul>
								</td>
								<!-- <td :rowspan="item.order_goods.length" align="center" v-if="index2==0"></td> -->
							</tr>
						</tbody>
					</template>
					<tbody v-else>
						<tr>
							<td colspan="8" align="center"><span style="color: #999999;">{{$t('seller.empty.orderList')}}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<el-dialog
				:visible.sync="orderVisible"
				width="600px"
				:before-close="handleHide">
				<div class="message-notice">
					<i class="el-icon-warning"></i>
					<div class="message-notice-main">
						<p>{{$t('seller.order.closeTips1')}}</p>
					</div>
				</div>
				<el-form>
					<el-form-item :label="$t('seller.formItem.closeReason')">
						<el-select v-model="closeOrder.remark" :placeholder="$t('seller.placeholder.closeReason')">
							<el-option v-for="(item,index) in reasonOption" :key="index" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<div style="text-align: right;">
							<el-button type="primary" @click="handleClose">{{$t('seller.actions.confirm')}}</el-button>
							<el-button @click="handleHide">{{$t('seller.actions.close')}}</el-button>
						</div>
					</el-form-item>
				</el-form>
				<div class="dialog-tip">
					<div class="tip-label">{{$t('seller.order.closeTips2')}}</div>
					<ul class="tip-block">
						<li>{{$t('seller.order.closeTips3')}}</li>
						<li>{{$t('seller.order.closeTips4')}}</li>
					</ul>
				</div>
			</el-dialog>
			<div class="uu-foot">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('seller.pagination.prevText')" :next-text="$t('seller.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { getOrderList, closeOrder, getCloseReasons } from '@/api/seller/order';
	
	const defaultForm = {
		order_sn: '',
		status: '',
		goods_ids: '',
		goods_name: '',
		buyer_name: '',
		evaluation_status: '',
		shipping_type: '',
		service_status: '',
		close_status: '1',
		time_from: '',
		time_to: '',
	}

	const defaultCloseForm = {
		order_sn: '',
		remark: ''
	}

	export default {
		filters: {
			statusFilter(status,statusMap) {
				return statusMap[status]
			}
		},
		data() {
			return {
				screenForm: Object.assign({}, defaultForm),
				orderStatusOption: this.$t('seller.order.statusOption'),
				evaluationOption: this.$t('seller.order.evaluationOption'),
				shippingOption: this.$t('seller.order.shippingOption'),
				afterSaleOption: this.$t('seller.order.afterSaleOption'),
				// closeStatus: '1',
				screenDatetime: '',
				startPickerOptions: {
					disabledDate: (time) => {
						let date = new Date();
						let endTime = new Date(date.getFullYear(),date.getMonth(),date.getDate(),23,59,59);
						return time.getTime() > endTime.getTime();
					}
				},
				screenClose: true,
				pageName: 'recent',
				pageNav: this.$t('seller.order.pageNav'),
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				pageList: [],
				checkAll: false,
				checkIds: [],
				isIndeterminate: false,
				orderVisible: false,
				closeOrder: Object.assign({}, defaultCloseForm),
				reasonOption: []
			}
		},
		created() {
			if(this.$route.query.type){
				if(this.$route.query.type=='evaluated'){
					this.screenForm.evaluation_status = '1';
				}else {
					this.screenForm.status = this.$route.query.type;
				}
				this.handleSearchList();
			}else{
				let startTime = new Date(new Date().getFullYear(),new Date().getMonth()-3,new Date().getDate());
				this.screenForm.time_from = Math.round(startTime.getTime()/1000);
				this.screenForm.time_to = Math.round(new Date().getTime()/1000);
				this.getPageList();
			}
			this.getReasonOption()
		},
		methods: {
			imageLoadError(e){
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			getReasonOption() {
				getCloseReasons({ uuid: this.$store.getters.uuid }).then( response => {
					const { data } = response
					this.reasonOption = data.cancel_order_reasons || []
				})
			},
			getPageList(){
				let that = this;
				let loading = that.$loading();
				let param = {
					...that.screenForm,
					page_index: (that.pageCurr - 1) * that.pageSize,
					page_size: that.pageSize
				};
				if(that.screenDatetime){
					if(that.pageName=='recent'){
						if(Math.round(that.screenDatetime[0] / 1000)>param.time_from){
							param.time_from = Math.round(that.screenDatetime[0] / 1000);
						}
						param.time_to = Math.round(that.screenDatetime[1] / 1000);
					}else if(that.pageName=='history'){
						param.time_from = Math.round(that.screenDatetime[0] / 1000);
						if(Math.round(that.screenDatetime[1] / 1000)<param.time_to){
							param.time_to = Math.round(that.screenDatetime[1] / 1000);
						}
					}else {
						param.time_from = Math.round(that.screenDatetime[0] / 1000);
						param.time_to = Math.round(that.screenDatetime[1] / 1000);
					}
				}
				getOrderList(param).then(response => {
					loading.close();
					that.pageList = response.data.list;
					that.pageTotal = parseInt(response.data.total);
				}).catch(() => {
					loading.close();
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
			handleSearchList(){
				if (this.screenForm.evaluation_status == '1') {
					this.pageName = 'evaluate';
				} else if (this.screenForm.status) {
					switch (this.screenForm.status) {
						case 'pending':
							this.pageName = 'pending';
							break;
						case 'accepted':
							this.pageName = 'accepted';
							break;
						case 'shipped':
							this.pageName = 'shipped';
							break;
						case 'refund_pay':
							this.pageName = 'refund_pay';
							break;
						case 'finished':
							this.pageName = 'finished';
							break;
						case 'canceled':
							this.pageName = 'canceled';
							break;
						default:
							this.pageName = 'recent';
					}
				}
				if(this.pageName!=='recent'){
					this.screenForm.close_status = '1';
				}
				this.checkIds = [];
				this.pageCurr = 1;
				this.getPageList();
			},
			handleSearchClear(){
				this.screenForm = Object.assign({}, defaultForm)
				this.screenDatetime = '';
				let startTime = new Date(new Date().getFullYear(),new Date().getMonth()-3,new Date().getDate());
				if(this.pageName !== 'history'){
					this.pageName = 'recent'
					this.screenForm.time_from = Math.round(startTime.getTime()/1000);
					this.screenForm.time_to = Math.round(new Date().getTime()/1000);
				} else {
					this.screenForm.time_to = Math.round(startTime / 1000);
				}
				this.pageCurr = 1;
				this.getPageList();
			},
			handleClickPage(tab, event){
				this.screenForm = Object.assign({}, defaultForm)
				var curDate = new Date();
				let startTime = new Date(curDate.getFullYear(),curDate.getMonth()-3,curDate.getDate());
				switch (tab.paneName) {
					case 'recent':
						this.screenForm.time_from = Math.round(startTime.getTime()/1000);
						this.screenForm.time_to = Math.round(curDate.getTime()/1000);
						break;
					case 'pending':
						this.screenForm.status = 'pending';
						break;
					case 'accepted':
						this.screenForm.status = 'accepted';
						break;
					case 'shipped':
						this.screenForm.status = 'shipped';
						break;
					case 'refund_pay':
						this.screenForm.status = 'refund_pay';
						break;
					case 'evaluate':
						this.screenForm.evaluation_status = '1';
						break;
					case 'finished':
						this.screenForm.status = 'finished';
						break;
					case 'canceled':
						this.screenForm.status = 'canceled';
						break;
					case 'history':
						// var endDate = curDate.setMonth(curDate.getMonth() - 3);
						this.screenForm.time_to = Math.round(startTime.getTime() / 1000);
						break;
				}
				this.screenDatetime = '';
				this.checkIds = [];
				this.pageCurr = 1;
				this.getPageList();
			},
			handleCheckAllChange(val) {
				let ids = [];
				if (val) {
					this.pageList.forEach(item => {
						ids.push(item.order_id);
					})
				}
				this.checkIds = ids;
				this.isIndeterminate = false;
			},
			handleCheckedOrderChange(val) {
				let checkCount = this.checkIds.length;
				this.checkAll = checkCount === this.pageList.length;
				this.isIndeterminate = checkCount > 0 && checkCount < this.pageList.length;
			},
			handleBatchConsign(){
				if(this.checkIds && this.checkIds.length>0){
					let not_order = '';
					this.pageList.forEach(item => {
						if(this.checkIds.includes(item.order_id) && not_order===''){
							if(item.status!==20){
								not_order = item.order_sn;
							}
						}
					})
					if(not_order){
						this.$alert(not_order+this.$t('seller.alert.notBatchShipment'));
					}else {
						this.$router.push({name:'consignOrder',params:{type:'logis',id:this.checkIds.join(',')}});
					}
				} else {
					this.$message.error(this.$t('seller.errorMsg.selectOrder'));
				}
			},
			handleShow(number) {
				this.orderVisible = true
				this.closeOrder.order_sn = number
			},
			handleHide() {
				this.orderVisible = false;
			},
			handleClose() {
				if(this.closeOrder.remark === '') {
					this.$message(this.$t('seller.infoMsg.closeReason'))
					return false
				}
				closeOrder(this.closeOrder).then(() => {
					this.orderVisible = false
					this.getPageList()
					this.$message.success(this.$t('seller.successMsg.close'))
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.trade-platform {
		padding: 18px 24px;
		
		.message-notice {
			padding: 12px;
			border-radius: 9px;
			line-height: 18px;
			background-color: $--seller-notice-background;
			margin-bottom: 15px;
			
			.el-icon-warning {
				color: $--seller-primary;
				float: left;
				font-size: 18px;
			}
			
			.message-notice-main {
				width: 100%;
				color: #666666;
				font-size: 14px;
				line-height: 22px;
				padding-left: 24px;
				padding-right: 15px;
				box-sizing: border-box;
				
				a {
					color: $--seller-primary;
				}
			}
		}

		.trade-platform-head {
			.uu-screen {
				margin: 0 -9px;

				::v-deep .el-form-item {
					width: calc(25% - 18px);
					margin: 0px 9px 18px;

					.el-form-item__content {
						.el-select {
							width: 100%;
						}
					}
				}

				::v-deep .el-form--inline {
					.el-form-item__content {
						width: 100%;

						.el-input__inner {
							background-color: $--seller-filter-input-background;
						}

						.el-input__inner:focus,
						.el-input__inner:hover {
							background-color: #FFFFFF;
							border-color: $--seller-primary;
						}

						.el-input--prefix {
							.el-input__inner {
								padding-left: 72px;
							}
						}

						.uu-prefix-100 {
							.el-input--prefix {
								.el-input__inner {
									padding-left: 100px;
								}
							}
						}

						.el-date-editor--datetimerange.el-input__inner {
							width: 100%;
							padding-left: 52px;
						}

						.uu-date-picker {
							position: relative;

							.picker-prefix-label {
								top: 0;
								left: 10px;
								z-index: 9;
								height: 100%;
								color: #C0C4CC;
								text-align: center;
								position: absolute;
							}
						}
					}
				}

				::v-deep .el-input__prefix {
					left: 10px;
				}

				.range-number {
					line-height: 34px;
					padding: 2px 0;
					display: flex;

					.input-medium {
						flex: 1;
						width: 60px;
						height: 34px;
						line-height: 34px;
						border-radius: 4px;
						background-color: $--seller-filter-input-background;
						padding: 0 9px;
						box-sizing: border-box;
					}

					.input-medium:focus,
					.input-medium:hover {
						background-color: #FFFFFF;
					}

					.range-separate {
						padding: 0 5px;
					}
				}
			}

			.clear-btn {
				cursor: pointer;
				color: #666666;
				font-size: 14px;
				margin-left: 12px;

				i {
					margin-right: 4px;
				}
			}

			.clear-btn:hover {
				color: $--seller-primary;
			}
		}

		.uu-toggle {
			height: 30px;
			line-height: 30px;
			color: #999999;
			text-align: center;
			cursor: pointer;
			border-bottom: 1px solid #f1f1f1;

			i {
				margin-right: 4px;
			}
		}

		.uu-action {
			li {
				display: inline-block;
				vertical-align: middle;
				margin-right: 12px;
			}
		}
		.mb20 {
			margin-bottom: 20px;
		}

		::v-deep .el-tabs__header {
			margin-bottom: 20px;

			.el-tabs__nav-wrap {
				padding: 0 24px;

				.el-tabs__nav-scroll {
					.el-tabs__nav {
						.el-tabs__item {
							height: 49px;
							line-height: 49px;
						}
					}
				}
			}

			.el-tabs__nav-wrap::after {
				height: 1px;
			}
		}

		.trade-platform-list {

			thead:after,
			tbody:after {
				content: "";
				display: block;
				height: 6px;
			}

			th,
			td {
				padding: 12px;
				line-height: 20px;
				font-size: 14px;
			}

			th {
				background-color: $--seller-thead-background-2;
			}

			th:first-child {
				border-top-left-radius: 9px;
				border-bottom-left-radius: 9px;
			}

			th:last-child {
				border-top-right-radius: 9px;
				border-bottom-right-radius: 9px;
			}

			.group-header {
				td {
					background-color: #f7f8fa;
				}

				td:first-child {
					border-top-left-radius: 9px;
					border-bottom-left-radius: 9px;
				}

				td:last-child {
					border-top-right-radius: 9px;
					border-bottom-right-radius: 9px;
				}
			}

			.item-head {
				li {
					display: inline-block;
					margin-right: 50px;
				}
			}

			.item-goods {
				display: flex;

				.pic {
					img {
						width: 68px;
						height: 68px;
						border-radius: 12px;
					}
				}

				.item-main {
					flex: 1;
					width: 0;
					padding-left: 15px;

					.name {
						max-height: 44px;
						display: block;
						font-size: 14px;
						line-height: 22px;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						a {
							color: $--seller-primary;
						}
					}
					.number {
						color: #777777;
						font-size: 12px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
			.item-status {
				li {
					text-align: center;
					padding: 5px 0;
					a {
						color: $--seller-primary;
					}
					span {
						font-size: 12px;
						color: #777777;
					}
				}
			}
			
			.item-price {
				li {
					text-align: center;
					span {
						font-size: 12px;
						color: #777777;
					}
				}
			}
		}

		.uu-foot {
			padding: 15px 0;
			text-align: center;
		}
		.dialog-tip {
			display: flex;
			line-height: 18px;
			.tip-label {
				color: $--uucolor-danger;
				font-size: 12px;
			}
			.tip-block {
				flex: 1;
				width: 0;
				list-style: decimal;
				padding-left: 16px;
				margin-left: 10px;
				li {
					font-size: 12px;
				}
			}
		}
	}
</style>
